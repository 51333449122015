<template>
	<div class="main">
    
	      <div class="main-container" v-if="option == 'individual'">
        <div class="market-content">
          
          <div class=" table-responsive">
                <button class="btn btn-secondary" @click.prevent="addIndividual()" :disabled="!init.FXCUSTEDIT">
                 +  Add Individual Member
                </button>
                <table> 
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                       Name:
                      </th>
                      <th>
                       Date of Birth:
                      </th>
                       <th>
                       Association Type:
                      </th>
                      <th>
                       Shares Held:
                      </th>
                      <th>
                        AMLC watchlist
                      </th>
                      <!-- <th>
                        Address Verified
                      </th> -->
                      <th>
                        ID Verified
                      </th>
                      <th>
                        Namecreen Result
                      </th>
                    </tr>
                  </thead> 
                  <tbody> 
                    <td v-if="show.profile">{{ show.profile.entityId }}</td>
                    <td v-if="show.profile">{{ show.profile.fullName }}</td>
                    <td v-if="show.profile">{{ formatDate(show.profile.birthDate) }}</td>
                    <td v-if="show.profile">Authorized Representative</td>
                    <td v-if="show.profile">{{ show.profile.noOfShares }}</td>
                    <td v-if="show.profile"><button @click="openWatchlistRep()" class="btn btn-secondary">WATCHLIST</button></td>
                    <!-- <td v-if="show.profile.addressVerified == true" >VERIFIED</td>
                   <td v-if="show.profile.addressVerified == false" >UNVERIFIED</td> -->
                   <td v-if="show.profile.idVerified == true" >VERIFIED</td>
                   <td v-if="show.profile.idVerified == false" @click="openVerify()"><button class="btn btn-secondary">Verify NOW</button></td>
                   <td><button class="btn btn-secondary"  @click="openNamescreenRep()" :disabled="!init.FXNAMESCREEN">Namescreen</button></td>
                  </tbody>
                </table>
                
                 <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                       Name:
                      </th>
                      <th>
                       Date of Birth:
                      </th>
                       <th>
                       Association Type:
                      </th>
                      <th>
                       Shares Held:
                      </th>
                      <th>
                        AMLC watchlist
                      </th>
                      <th>
                        Address Verified
                      </th>
                      <th>
                        ID Verified
                      </th>
                      <th>
                        Namecreen Result
                      </th>
                    </tr>
                  </thead>
               
                  <tbody v-for="member in directors" :key='member.id'>
                    <td>{{ member.directorId }}</td>
                    <td>{{member.firstName}} {{member.middleName}} {{member.lastName}}</td>
                   <td>{{member.birthDd}}/{{member.birthMm}}/{{member.birthYear}}</td>
                   <td>{{member.assocType}}</td>
                   <td>{{member.sharesHeld}}</td>
                 
                   <td v-if="member.watchList == '-'"><button class="btn btn-secondary" @click="openWatchlist(member)" :disabled="!init.FXCOMPLIANCE">Watchlist</button></td>
                   <td v-else>{{ member.watchList }}</td>
                   <td v-if="member.addressVerified == false"><button class="btn btn-secondary" @click="openAddressVerify(member)" :disabled="!init.FXCOMPLIANCE">Verify Address</button></td>
                   <td v-else>VERIFIED</td>
                   <td v-if="member.idVerified == false"><button class="btn btn-secondary" @click="openIdVerify(member)" :disabled="!init.FXCOMPLIANCE">Verify ID</button></td>
                   <td v-else >VERIFIED</td>
                   <td ><button class="btn btn-secondary"  @click="openNamescreen(member)" :disabled="!init.FXNAMESCREEN">Namescreen</button></td>
                  </tbody>
                 </table>
              </div>
              </div>
              <div>
               
                
                <div v-if="tab == 'watchlist'">
                    <div v-if="!isWatchlistMatch" class="market-content"> 
                      <table >
                        <thead>
                        <tr>
                            <th>Watchlist Assessment</th>
                            <th>Processed By</th>
                            <th>Assessment Date</th>
                            <th>Download Assessment</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr v-for="list in listedOnly" :key="list.id">
                          <td>{{ list.remarks }}</td>
                          <td>{{ list.addedBy }}</td>
                          <td>{{ formatDate(list.dateCreated) }}</td>  
                          <td class="link" >
                            <table class="inside-table">
                              <tbody v-for="item in watchlistOnly" :key="item.id">
                                <tr >
                                  <td @click="viewUpload(item)">
                                    <button class="btn btn-cancel">view</button></td>
                                  <td @click="download(item)">
                                    <button class="btn btn-cancel">download</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                <div class="market-content">
                 
                  <div >
                  
                  <div class="market-content" v-if="showNamescreenResult">
                   
                    <h5 >Scanned results for {{ member.directorId }} : {{ member.firstName }} {{ member.middleName }} {{ member.lastName }}</h5>
                    <div  v-if="isLoading" class="loading-spinner"></div>
                  <table v-if="!isLoading">
                        <tbody >
                          <tr v-if="scanHistory.length == 0">
                            <td>No results found</td>
                          </tr>
                        </tbody>
                        <tbody >
                          <tr v-if="!isMatched">
                            <td>No data</td>
                          </tr>
                          <tr class="d-flex justify-content-between" v-if="isMatched">
                            <td >Full name: {{scannedNamesMember}}</td>
                            <td>
                              <button class="btn btn-secondary" @click="expand(scanId)">view result</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button class="btn btn-main" @click="beginScanMember(member)" v-if="!isMatched" :disabled="!init.FXNAMESCREEN">Begin Scan</button>
                  </div>

                <div class="market-content" v-if="showNamescreenResultRep">
                   
                  <h5>Scanned results for {{ show.profile.entityId }} : {{ show.profile.firstName }} {{ show.profile.middleName }} {{ show.profile.lastName }}</h5>
                  <div  v-if="isLoading" class="loading-spinner"></div>
                  <table v-if="!isLoading">
                       <tbody >
                         <tr v-if="scanHistory.length == 0">
                           <td>No results found</td>
                         </tr>
                       </tbody>
                       <tbody >
                         <tr v-if="!isMatched">
                           <td>No data</td>
                         </tr>
                         <tr class="d-flex justify-content-between" v-if="isMatched">
                          
                           <td >Full name: {{scannedNames}} </td>
                           <td>
                             <button class="btn btn-secondary" @click="expand(scanId)">view result</button>
                           </td>
                         </tr>
                       </tbody>
                     </table>
                     <button class="btn btn-main" @click="beginScanRep()" v-if="!isMatched" :disabled="!init.FXNAMESCREEN">Begin Scan</button>
                  </div>
                   
                  <br>

                 
                </div>
                
                </div>
                </div>
       </div>
             
        
    <WatchlistMember v-if="showWatchlistComment" @close="openWatchlist()" :member="member"></WatchlistMember>
    <AddressVerifyMember v-if="showAddressComment" @close="openAddressVerify()" :member="member"></AddressVerifyMember>
    <IdVerifyMember v-if="showIdComment" @close="openIdVerify()" :member="member"></IdVerifyMember>
    <UploadWatchlist v-if="showUploadWatchlist" @close="openUploadWatchlist()"></UploadWatchlist>
    <UploadIdVerify v-if="showUploadIdVerify" @close="openUploadIdVerify()"></UploadIdVerify>
    <UploadAddressVerify v-if="showUploadAddressVerify" @close="openUploadAddressVerify()"></UploadAddressVerify>
    <UploadNamescreen v-if="showUploadNamescreen" @close="openUploadNamescreen()"></UploadNamescreen>
    <ViewUpload v-if="showUpload" @close="viewUpload(item)" :data="dataInfo"></ViewUpload>
    <AddMember v-if="showAddMember" @close="addIndividual()"/>
    <IdVerify v-if="showIdVerify" @close="openVerify()"></IdVerify>
    <Watchlist v-if="showWatchlist" @close="openWatchlistRep()" ></Watchlist>
    <NamescreenRep v-if="showScanButtonRep" @close="beginScanRep()"></NamescreenRep>
    <NamescreenMember v-if="showScanButtonMember" @close="beginScanMember()" :member="member"></NamescreenMember>
	</div>
  
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import UploadWatchlist from '../components/modal/Documents/UploadWatchlist.vue'
  import UploadIdVerify from '../components/modal/Documents/UploadIdVerify.vue'
  import UploadAddressVerify from '../components/modal/Documents/UploadAddressVerify.vue'
  import UploadNamescreen from '../components/modal/Documents/UploadNamescreen.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
  import AddMember from '../components/modal/Documents/AddMember.vue'
  import Watchlist from '../components/modal/Compliance/Watchlist.vue'
  import AddressVerify from '../components/modal/Compliance/AddressVerify.vue'
  import IdVerify from '../components/modal/Compliance/IdVerify.vue'
  import WatchlistMember from '../components/modal/Compliance/WatchlistMember.vue'
  import AddressVerifyMember from '../components/modal/Compliance/AddressVerifyMember.vue'
  import IdVerifyMember from '../components/modal/Compliance/IdVerifyMember.vue'
  import NamescreenRep from '../components/modal/Compliance/NamescreenRep.vue';
  import NamescreenMember from '../components/modal/Compliance/NamescreenMember.vue';

  export default {
      name: 'IndividualDueDiligence',
      components: {
        TheTopbar,
        Header,
        UploadWatchlist,
        UploadIdVerify,
        UploadAddressVerify,
        UploadNamescreen,
        ViewUpload,
        AddMember,
        Watchlist,
        AddressVerify,
        IdVerify,
        WatchlistMember,
        AddressVerifyMember,
        IdVerifyMember,
        IdVerify,
        NamescreenRep,
        NamescreenMember


      },
      
       data: function() {
      return { 
          option: 'individual',
          tab:'',
          isLoading: false,
          showWatchlist: false,
          showWatchlistComment: false,
          showIdComment: false,
          showAddressComment: false,
          showNamescreenComment: false,
          showUpload: false,
          showUploadWatchlist: false,
          showUploadIdVerify: false,
          showUploadAddressVerify: false,
          showUploadNamescreen: false,
          watchlistComment:'',
          idComment: '',
          addressComment:'',
          namescreenComment:'',
          isApproved: false,
          showUpload: false,
          itemList: [],
          showAdd: false,
          showComment: false,
          showCountryForAddress: false,
          showCountry: false,
          country: 'PH',
          mobileCode: '63',
          list: {
                mobileCodes: []
          },
          acountry: 'AR',
          rate:'',
          remarks:'',
          reviewId:'',
          details:[],
          detail:{},
          matchType:'Close',
          closeMatchRateThreshold:'80',
          whitelist:false, 
          residences: [],
          residenceValue:'',
          options: [
                    { text: 'Apply PEP,', value: 'ApplyPEP' },
                    { text: 'Apply SIP,' , value: 'ApplySIP'},
                    { text: 'Apply RCA,' , value: 'ApplyRCA'},
                    { text: 'Apply POI,' , value: 'ApplyPOI'},
                    { text: 'Apply All,' , value: 'ApplyAll'},
                    { text: 'Ignore,' , value: 'Ignore'},
                    
                  ],
          excludeDeceasedPersons: false,
          pepJurisdiction: true,
          includeWebSearch: false,
          includeJurisdictionRisk: true,
          rates: Array.from({ length: 100 }, (v, i) => (i + 1).toString().padStart(2, '0')),
          days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
          month:'',
          day:'',
          year:'',
          isWatchlistMatch:false,
          watchList:[],
          listed:[],
          idTypes:[],
          item:{
            fileFormat:''
          },
          showAddMember: false,
          watchlistFile:'WATCHLIST',
          namesceenRemarks:'',
          showNamescreenResult: false,
          showNamescreenResultRep: false,
          memberScan:'',
          scanId:'',
          scanData: {},
          scanHistory:[],
          showSettings: false,
          showExpand: false,
          profile:{},
          showScanButtonSettings:false,
          showScanButtonMember: false,
          showScanButtonRep: false,
          isScanDone: false,
          scan:{
            firstName:'',
            middleName:'',
            lastName: ''
          },
          member:{
            firstName:'',
            middleName:'',
            lastName:''
          },
          directors:[],
          scannedNames:'',
          scannedNamesMember:'',
          memberName:'',
          repName:'',
          isMatched: false,
          status:'',
          showErrorMsg:false,
          showIdVerify: false,
        }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        listedOnly(){
          return this.watchList.filter(item => item.entityId === this.$route.params.entityId)
        },
        watchlistOnly(){
          return this.idTypes.filter(item => item.fileType === this.watchlistFile)
        },
       
     
      },
     
      methods:{
        openWatchlistRep(){
            this.showWatchlist = !this.showWatchlist;
          },
        handleSelectChange() {
          if (this.residences.includes('ApplyAll')) {
            this.residences = ['ApplyAll'];
          }
          if (this.residences.includes('Ignore')) {
            this.residences = ['Ignore'];
            return;
          }
          
        },
        optionSelected(option, id) {
          this.residences = option.value
          this.residenceValue.push(this.residences)
        },
        viewScanResult(memberScan){
          this.scanId = memberScan.scanId
          window.location.href="/#/name-matches/" + this.scanId
        },
        expand(scanId){
         this.scanId = scanId
          this.isLoading = true
       
          window.location.href="/#/name-matches/" + scanId
        },
        openVerify(){
          this.showIdVerify = !this.showIdVerify
        },
        beginScanRep(){
          this.showScanButtonRep = !this.showScanButtonRep
          
        },
        beginScanMember(member){
          this.member = member
          this.showScanButtonMember = !this.showScanButtonMember
        },
        openSettings(){
          this.showSettings = !this.showSettings
        },
        showAddItem(){
         this.showAdd = !this.showAdd
        },
        goToCompliance(){
          window.location.href="/#/compliance"
        },
        addComment(){
          this.showComment = !this.showComment
        },
        updateSetting(){

        },
        changeState(option) {
            this.option = option;
            localStorage.setItem("option", option);
          },
        opentab(tab) {
            this.tab = tab;
            localStorage.setItem("tab", tab);
        },
       
        getState() {
            let option = localStorage.getItem("option")
            if(option != "" || option != undefined) {
            this.option = option
            }
        },
        getTab() {
            let tab = localStorage.getItem("tab")
            if(tab != "" || tab != undefined) {
            this.tab = tab
            }
        },
        addIndividual(){
          this.showAddMember = !this.showAddMember
        },
        openUploadWatchlist(result){
          this.watchlistComment = result
          this.showUploadWatchlist = !this.showUploadWatchlist
        },
        openUploadIdVerify(){
          this.showUploadIdVerify = !this.showUploadIdVerify
        },
        openUploadAddressVerify(){
          this.showUploadAddressVerify = !this.showUploadAddressVerify
        },
        openUploadNamescreen(){
          this.showUploadNamescreen = !this.showUploadNamescreen
        },
        openWatchlist(member){
            this.member = member
            this.showWatchlistComment = !this.showWatchlistComment;
          },
          openIdVerify(member){
            this.member = member
            this.showIdComment = !this.showIdComment;
          },
          openAddressVerify(member){
            this.member = member
            this.showAddressComment = !this.showAddressComment;
          },
          openNamescreenRep(){
            this.isLoading = true
            this.showNamescreenResultRep = true;
            this.showNamescreenResult = false;
            let middleName = ""
            if(this.show.profile.middleName == null){
              middleName = ""
              this.repName = this.show.profile.firstName + middleName + this.show.profile.lastName
            }else{
              this.repName = this.show.profile.firstName + this.show.profile.middleName + this.show.profile.lastName
              this.repName = this.show.profile.firstName +  this.show.profile.lastName
            }
            
            
            this.showNamescreenResult = false;
            this.showNamescreenResultRep = true;
            this.scannedNames = "";
            this.getScanHistory();
           
          },
          openNamescreen(member){
            this.isLoading = true
            console.log(member)
            this.member = member
            
            if(member.middleName == null){
              this.member.middleName = ""
              this.memberName = member.firstName + this.member.middleName + member.lastName

            }else{
              this.memberName = member.firstName + member.middleName + member.lastName
              this.memberName = member.firstName +  member.lastName
            }
            
            this.showNamescreenResult = true;
            this.showNamescreenResultRep = false;
            this.scannedNamesMember = "";
            this.getScanHistoryMember();
            
          },
        
          closeNamescreen(){
            this.tab = ''
          },
          updateDate() {
            if (this.day && this.month && this.year) {
              // Do any additional validation or formatting if needed
              const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
         
            }
          },
          toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.acountry = c.countryCode
           
          },
         
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {

              })
          },
        
       
        formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY');
        },
        
        
         
         
          getWatchlist(){
                const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: ' /ax/watchlist',
                    };

                  this.axios(options)
                  .then((response) => { 
                  this.watchList = response.data

                  this.watchList.forEach((el, i) => {
                    if(el.entityId == this.$route.params.entityId){
                      this.isWatchlistMatch = true
                      this.listed = el
                    }
                  })
                
                })
          },
         
            download(item){
            this.imageUrl = item.imageFile
            this.fileType = this.imageUrl.split('.').pop();

              const params = {
                entityId: this.$route.params.entityId
            }
              
            const options = {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId") 
                    }, 
                    params,
                    url: '/ax/customer/businessfile/image/' + item.businessfileId,
                    }
                    this.axios(options)
                    .then((response) => {
                      
                      
                    if(this.fileType === 'pdf'){
                      const link = document.createElement('a');
                      link.href = "data:application/pdf;base64," + response.data
                      link.download = 'file'; 
                      link.click();
                    }

                    if(this.fileType !== 'pdf'){
                      const link = document.createElement('a');
                      link.href = "data:image/png;base64," + response.data
                      link.download = 'file'; 
                      link.click();
                    }
                  
                    
                    }).catch((err) => {
                        
                    })
          },
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile/',
              }
              this.axios(options)
              .then((response) => {
                
                this.show.profile = response.data;
                this.show.corpDetails = response.data.corpDetails
                this.directors = response.data.corpDetails.diretors
              }).catch((err) => {
                  
              })
          },
         
          addToWatchlist(){
            const params = {
              remarks: this.watchlistComment,
              entityId: this.$route.params.entityId

            }
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/watchlist/add',
              }
              this.axios(options)
              .then((response) => {
                window.location.reload();
              }).catch((err) => {
              })
          
          },
         
          getScanHistory(){
              this.isMatched = false
              const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
                url: ' /ax/namescan/history'
                }
                this.axios(options)
                .then((response) => {
                  this.scanHistory = response.data
                  this.isLoading = false

                  let elementName = ''
                  let elementMiddleName = ''
                  let elementId = ''
                  this.scanHistory.forEach((el, i) => {
                    this.scan = el
                   
                    if( el.middleName == null ){
                        this.scan.middleName = ""
                    }
                    if(el.middleName != null){
                      elementName = this.scan.firstName + this.scan.lastName
                    }
                    elementMiddleName = this.scan.firstName + this.scan.middleName + this.scan.lastName
                    
                    
                    elementId = this.scan.scanId
                    if(this.repName.toLowerCase() === elementName.toLowerCase() || this.repName.toLowerCase() === elementMiddleName.toLowerCase()){
                      this.isMatched = true
                      this.scannedNames = elementName
                      this.scanId = elementId
                     

                      return;
                    }
                  })
                }).catch((err) => {
                    
                })
          },
            getScanHistoryMember(){
              this.isMatched = false
              const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
                url: ' /ax/namescan/history'
                }
                this.axios(options)
                .then((response) => {
                  this.isLoading = false
                  this.scanHistory = response.data

                  let elementName = ''
                  let elementMiddleName = ''
                  let elementId = ''
                  this.scanHistory.forEach((el, i) => {
                    this.scan = el
                   
                    if( el.middleName == null ){
                        this.scan.middleName = ""
                    }
                    
                    elementMiddleName = this.scan.firstName + this.scan.middleName + this.scan.lastName
                    elementName = this.scan.firstName + this.scan.lastName
                    console.log(elementName.toLowerCase())
                    
                    elementId = this.scan.scanId
                    
                    if(this.memberName.toLowerCase() === elementName.toLowerCase() || this.memberName.toLowerCase() === elementMiddleName.toLowerCase()){
                      
                      this.isMatched = true
                      this.scannedNamesMember = elementName
                      console.log(this.scannedNamesMember)
                      this.scanId = elementId
                      console.log(this.isMatched)

                      return;
                    } 
                    
                    
                    
                
                  })
                }).catch((err) => {
                    
                })
          },
         
           
         
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
              this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getWatchlist();
          this.getTab();
          this.getMobileCodes();
         
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .form-control-short{
    border-radius: 10px;
    padding: 10px;
  }
  .btn-main{
  width: 100%;
  border: 1px solid beige !important;
  color: white !important;
}
.btn-main.active{
  background: #007bff !important;
}
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  
  .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
   
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
 
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 200px;
  border-radius: 10px;

  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #053d5c !important;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}
.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
