<template>
	<div class="main">
    
	<div class="main-container" v-if="option == 'complianceRating'">
        
              <div >
                <button  class="btn btn-secondary"  @click="opentab('individualScore')">
                 Individual Profile Score
                </button>
                <button  class="btn btn-secondary"   @click="opentab('companyScore')" >
                 Company Profile Score
                </button>
                <button  class="btn btn-secondary"  @click="opentab('txnMonitoring')" >
                  BEH Score_txn Monitoring
                </button>
                <button  class="btn btn-secondary"  @click="opentab('riskLevel')" >
                  Risk Level Indicator
                </button>

                <button class="btn btn-secondary" @click="goToCompliance">+</button>
                <br>
                <h4 v-f="error.show" style="color: rgb(188, 113, 113)">{{ error.msg }}</h4>
                <div v-if="tab == 'individualScore'">
                    <div  class="market-content">
                      <table class="table">
                        <thead>
                          <tr>
                              <th>ID</th>
                              <th>Items to review</th>
                              <th>Action</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr v-for="item in idvItems" :key="item.id">
                            <td>{{item.reviewId}}</td>
                            <td>{{item.description}}</td>
                            <td class="d-flex" v-if="item.reviewId " >
                              <button class="btn btn-secondary" @click="openSubmitRating(item)">Rate</button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                    <button class="btn btn-secondary" @click="getResult()">Show rating</button>
                    <div v-if="showRating" > 
                    <div> {{ totalIdv }} / 40</div>
                      <div class=""> 
                      
                        <div class=""> 
                          <div class="market-content"> 
                            <table class="table" v-if="idvResult.length > 0">
                              <thead>
                                <tr>
                                    <th>Rating</th>
                                    <th>ID</th>
                                    <th>Items to review</th>
                                    <th>Remarks</th>
                                    <th>Rated by</th>
                                    <th>Process Date</th>
                                </tr>
                              </thead>
                              <tbody v-for="detail in idvResult" :key="detail.id">
                                <tr>
                                  <td>{{detail.rate}}</td>
                                  <td>{{detail.reviewId}}</td>
                                  <td>{{detail.description}}</td>
                                  <td>{{detail.remarks}}</td>
                                  <td>{{detail.ratedBy}}</td>
                                  <td>{{formatDate(detail.updateDate)}}</td>
                                
                                </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="column"> 
                          <div> 
                            <!-- <Chart :reviewId="reviewId"/> -->
                          </div>
                        </div>
                    </div>
                  </div>
                
                </div> 
                <div v-if="tab == 'companyScore'">
                    <div  class="market-content">
                      <table class="table">
                        <thead>
                          <tr>
                              <th>ID</th>
                              <th>Items to review</th>
                              <th>Action</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr v-for="item in cpyItems" :key="item.id">
                            <td>{{item.reviewId}}</td>
                            <td>{{item.description}}</td>
                            <td class="d-flex" v-if="item.reviewId " >
                              <button class="btn btn-secondary" @click="openSubmitRating(item)">Rate</button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                    <button class="btn btn-secondary" @click="getResult()">Show rating</button>
                    <div v-if="showRating" > 
                    <div> {{ totalCpy }} / 50</div>
                      <div class=""> 
                      
                        <div class=""> 
                          <div class="market-content"> 
                            <table class="table" v-if="cpyItems.length > 0">
                              <thead>
                                <tr>
                                    <th>Rating</th>
                                    <th>ID</th>
                                    <th>Items to review</th>
                                    <th>Remarks</th>
                                    <th>Rated by</th>
                                    <th>Process Date</th>
                                </tr>
                              </thead>
                              <tbody v-for="detail in cpyResult" :key="detail.id">
                                <tr>
                                  <td>{{detail.rate}}</td>
                                  <td>{{detail.reviewId}}</td>
                                  <td>{{detail.description}}</td>
                                  <td>{{detail.remarks}}</td>
                                  <td>{{detail.ratedBy}}</td>
                                  <td>{{formatDate(detail.updateDate)}}</td>
                                
                                </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                
                </div> 
                <div v-if="tab == 'txnMonitoring'">
                    <div  class="market-content">
                      <table class="table">
                        <thead>
                          <tr>
                              <th>ID</th>
                              <th>Items to review</th>
                              <th>Action</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr v-for="item in behItems" :key="item.id">
                            <td>{{item.reviewId}}</td>
                            <td>{{item.description}}</td>
                            <td class="d-flex" v-if="item.reviewId " >
                              <button class="btn btn-secondary" @click="openSubmitRating(item)">Rate</button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                    <button class="btn btn-secondary" @click="getResult()">Show rating</button>
                    <div v-if="showRating" > 
                    <div> {{ totalBeh }} / 10</div>
                      <div class=""> 
                        <div class=""> 
                          <div class="market-content"> 
                            <table class="table" v-if="behResult.length > 0">
                              <thead>
                                <tr>
                                    <th>Rating</th>
                                    <th>ID</th>
                                    <th>Items to review</th>
                                    <th>Remarks</th>
                                    <th>Rated by</th>
                                    <th>Process Date</th>
                                </tr>
                              </thead>
                              <tbody v-for="detail in behResult" :key="detail.id">
                                <tr>
                                  <td>{{detail.rate}}</td>
                                  <td>{{detail.reviewId}}</td>
                                  <td>{{detail.description}}</td>
                                  <td>{{detail.remarks}}</td>
                                  <td>{{detail.ratedBy}}</td>
                                  <td>{{formatDate(detail.updateDate)}}</td>
                                </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="column"> 
                          <div> 
                            <!-- <Chart :reviewId="reviewId"/> -->
                          </div>
                        </div>
                    </div>
                  </div>
                
                </div> 
                <div v-if="tab == 'riskLevel'">
                    <div  class="market-content">
                      <table class="table">
                        <thead>
                          <tr>
                              <th>ID</th>
                              <th>Items to review</th>
                              <th>Action</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr v-for="item in rliItems" :key="item.id">
                            <td>{{item.reviewId}}</td>
                            <td>{{item.description}}</td>
                            <td class="d-flex" v-if="item.reviewId " >
                              <button class="btn btn-secondary" @click="openSubmitRatingRli(item)">Rate</button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                    <button class="btn btn-secondary" @click="getResult()">Show rating</button>
                    <div v-if="showRating" > 
                   
                      <div class=""> 
                      
                        <div class=""> 
                          <div class="market-content"> 
                            <table class="table" v-if="rliResult.length > 0">
                              <thead>
                                <tr>
                                    <th>Rating</th>
                                    <th>ID</th>
                                    <th>Items to review</th>
                                    <th>Remarks</th>
                                    <th>Rated by</th>
                                    <th>Process Date</th>
                                </tr>
                              </thead>
                              <tbody v-for="detail in rliResult" :key="detail.id">
                                <tr>
                                  <td v-if="detail.rate == '1'">LOW</td>
                                  <td v-if="detail.rate == '2'">MEDIUM</td>
                                  <td v-if="detail.rate == '3'">HIGH</td>
                                  <td>{{detail.reviewId}}</td>
                                  <td>{{detail.description}}</td>
                                  <td>{{detail.remarks}}</td>
                                  <td>{{detail.ratedBy}}</td>
                                  <td>{{formatDate(detail.updateDate)}}</td>
                                
                                </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                
                    </div>
                  </div>
                
                </div> 

            </div>
          </div>
        
    <AddItem v-if="showAdd" @close="showAddItem()"></AddItem>
    <SubmitRate v-if="showSubmitRating" @close="openSubmitRating(ratedReview)" :item="ratedReview"></SubmitRate>
    <SubmitRateRli v-if="showSubmitRatingRli" @close="openSubmitRatingRli(ratedReview)" :item="ratedReview"></SubmitRateRli>
    <ViewUpload v-if="showUpload" @close="viewUpload(item)" :data="dataInfo"></ViewUpload>
	</div>
  
 
          
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import AddItem from '../components/modal/Documents/AddItem.vue'
  import SubmitRate from '../components/modal/Documents/SubmitRate.vue'
  import SubmitRateRli from '../components/modal/Documents/SubmitRateRli.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
  import IndividualDueDiligence from '../view/IndividualDueDiligence.vue'
  import NamescreenResult from '../components/modal/Compliance/NamescreenResult.vue';
  import Watchlist from '../components/modal/Compliance/Watchlist.vue'



  export default {
      name: 'CompanyRating',
      components: {
        TheTopbar,
        Header,
        AddItem,
        ViewUpload,
        IndividualDueDiligence,
        NamescreenResult,
        Watchlist,
        SubmitRate,
        SubmitRateRli
      },
      
       data: function() {
      return { 
          option: 'complianceRating',
          tab:'individualScore',
          showSettings: false,
         
          isApproved: false,
          showUpload: false,
          itemList: [],
          showAdd: false,
          showComment: false,
          rate:'',
          remarks:'',
          reviewId:'',
          details:[],
          detail:{},
        
          idTypes:[],
          item:{
            fileFormat:''
          },
         
          isLoading: false,
          showExpand: false,
          profile:{},
          memberName: '',
          error: {
            show: false,
            msg:''
          },
          status:'',
          showRating: false,
          ratedReview:{},
          ratedReviewId:'',
          isMatched: false,
          ratedId:'',
          showSubmitRating: false,
          showSubmitRatingRli: false
        
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        listedOnly(){
          return this.watchList.filter(item => item.entityId === this.$route.params.entityId)
        },
        watchlistOnly(){
          return this.idTypes.filter(item => item.fileType === this.watchlistFile)
        },
        totalRating() {
          return this.details.reduce((acc, item) => acc + item.rate, 0);
        },
        totalIdv() {
          return this.idvResult.reduce((acc, item) => acc + item.rate, 0);
        },
        totalCpy() {
          return this.cpyResult.reduce((acc, item) => acc + item.rate, 0);
        },
        totalBeh() {
          return this.behResult.reduce((acc, item) => acc + item.rate, 0);
        },
        totalRli() {
          return this.rliResult.reduce((acc, item) => acc + item.rate, 0);
        },
        idvItems() {
          const filterItem = 'IDV'
          return this.itemList.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        cpyItems() {
          const filterItem = 'CPY'
          return this.itemList.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        behItems() {
          const filterItem = 'BEH'
          return this.itemList.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        rliItems() {
          const filterItem = 'RLI'
          return this.itemList.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        idvResult() {
          const filterItem = 'IDV'
          return this.details.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        cpyResult() {
          const filterItem = 'CPY'
          return this.details.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        behResult() {
          const filterItem = 'BEH'
          return this.details.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
        rliResult() {
          const filterItem = 'RLI'
          return this.details.filter((obj) => obj.description.slice(0,3).toUpperCase() === filterItem.toUpperCase())
        },
     
      },
      methods:{
        viewScanResult(memberScan){
          this.scanId = memberScan.scanId
          window.location.href="/#/name-matches/" + this.scanId
        },
        expand(scanId){
          this.scanId = scanId
          this.isLoading = true
          // if(this.scannedNames == this.memberName){
          //   window.location.href="/#/name-matches/" + scanId
          // }
          // else {
          //   this.
          // }
          window.location.href="/#/name-matches/" + scanId
        },
        beginScan(){
          this.showScanButton = !this.showScanButton
        },
        showAddItem(){
         this.showAdd = !this.showAdd
        },
        goToCompliance(){
          window.location.href="/#/compliance"
        },
        openUploadWatchlist(result){
          this.watchlistComment = result
          this.showUploadWatchlist = !this.showUploadWatchlist
        },
        openUploadIdVerify(){
          this.showUploadIdVerify = !this.showUploadIdVerify
        },
        openUploadAddressVerify(){
          this.showUploadAddressVerify = !this.showUploadAddressVerify
        },
        openSettings(){
          this.showSettings = !this.showSettings
        },
        changeState(option) {
            this.option = option;
            localStorage.setItem("option", option);
        },
        openSubmitRating(item){
          this.ratedReview = item
          // this.reviewId = item.reviewId
          this.showSubmitRating = !this.showSubmitRating
          // if(this.reviewid == this.ratedReviewId){
          //   this.isMatched = true
          //   this.showSubmitRating = true
          // } else{
          //   this.showSubmitRating = false
          //   this.submitReview(item);
          // }

        },
        openSubmitRatingRli(item){
          this.ratedReview = item
          this.showSubmitRatingRli = !this.showSubmitRatingRli
        },
        opentab(tab) {
            this.tab = tab;
            localStorage.setItem("tab", tab);
        },
        getState() {
            let option = localStorage.getItem("option")
            if(option != "" || option != undefined) {
            this.option = option
            }
        },
        getTab() {
            let tab = localStorage.getItem("tab")
            if(tab != "" || tab != undefined) {
            this.tab = tab
            }
        },
      
        openUploadWatchlist(result){
          this.watchlistComment = result
          this.showUploadWatchlist = !this.showUploadWatchlist
        },
        openUploadIdVerify(){
          this.showUploadIdVerify = !this.showUploadIdVerify
        },
        openUploadAddressVerify(){
          this.showUploadAddressVerify = !this.showUploadAddressVerify
        },
        openUploadNamescreen(){
          this.showUploadNamescreen = !this.showUploadNamescreen
        },
        updateDate() {
          if (this.day && this.month && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
        getListOfItems(){
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              url: '/ax/review/items'
              }
              this.axios(options)
              .then((response) => {
                this.itemList = response.data
              }).catch((err) => {
                  
              })
        },
       
        formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
        
          getResult(){
            this.ismatched = false;
            this.showRating = true;

            const params = {
              entityId: this.$route.params.entityId
            }
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/items/entityid',
              }
              this.axios(options) 
              .then((response) => {
                console.log(response.data)
                this.details = response.data

                let ratedReviewId = ''
                this.details.forEach((el, i) => {
                  this.ratedReview = el

                  ratedReviewId = this.ratedReview.reviewId

                  console.log(this.reviewId + '=' + ratedReviewId)

                    if(this.reviewId === ratedReviewId){
                      console.log(this.isMatched)
                      this.isMatched = true
                      this.ratedReviewId = ratedReviewId
                      return;
                    } 
                })
                
              }).catch((err) => {
                  
              })
          },
         
         
              getProfile() {
              const params = {
                eid: this.$route.params.entityId
              }
              const data = Object.keys(params)
                        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                        .join('&');

              const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
                data,
                url: '/ax/getCustomerProfile/',
                }
                this.axios(options)
                .then((response) => {
                  
                  this.show.profile = response.data;
                  this.show.corpDetails = response.data.corpDetails

                  if( response.data.middleName == null ){
                          this.show.profile.middleName = ""
                  }
                 

                  this.memberName = this.show.profile.firstName + this.show.profile.middleName + this.show.profile.lastName
                 

                  console.log(this.memberName)
                
                }).catch((err) => {
                    
                })
            },
           toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.acountry = c.countryCode
           
          },
         
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
              this.axios(options)
              .then((response) => {
              
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      beforeMount(){
        this.getProfile();
      },
      mounted(){
          this.sessionCheck();
          this.getListOfItems();
          this.getTab();
          this.getState();
          this.getResult();
          
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  .form-control-short{
    border-radius: 10px;
    padding: 10px;
  }
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  
  .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
   
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
 
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }

  .enhanced{
    margin-top: 2em;
  }
.btn-main{
  width: 100%;
  border: 1px solid beige !important;
  color: white !important;
}
.btn-main.active{
  background: #007bff !important;
}

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}
.btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }
  .btn-cancel:hover{
        float: right;
        background-color: #007bff;
        color: white;
 }
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 200px;
  border-radius: 10px;

  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #053d5c !important;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}
.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
