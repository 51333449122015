<template>
    <div>
      <canvas ref="pieChart" width="400" height="400">{{  }}</canvas>
    </div>
  </template>
  
  <script>
  import Chart from 'chart.js';
  
  export default {
    props:["reviewId"],
    data() {
      return {
        details: [],
        // Data for the pie chart
        chartData: {
          labels: ['Label 1', 'Label 2', 'Label 3'],
          datasets: [{
            data: [30, 40, 30],
            backgroundColor: ['red', 'blue', 'green'],
          }],
        },
      };
    },
    mounted() {
      // Create the pie chart when the component is mounted
      this.viewReview();
    },
    methods: {
        viewReview(){
           
            
            const params = {
              reviewId: this.reviewId
            }
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/rate/list',
              }
              this.axios(options) 
              .then((response) => {
                console.log(response.data)
                this.details = response.data

                
              }).catch((err) => {
                  
              })
          },
    },
  };
  </script>
  
  <style scoped>
  /* Your component-specific styles */
  </style>
  