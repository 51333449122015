<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">ID Verification </h5>
            <p @click="closeModal()" class="m-2 x">x</p>
        </div>
      <div class="modalBody" v-if="state == 'init'">
        <div > 
          
              <div class="text-center">
                <h5>Match Decision</h5>
                <p style="color: rgb(193, 99, 99)">{{ errorMsg }}</p>
                          <div class="">
                          
                          <div >
                            <div v-if="show.profile.ideVerified == true">VERIFIED</div>
                            <select v-model="idComment" class="form-control" placeholder="Match result" v-if="show.profile.idVerified != true">
                              <option value="" disabled>Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            <button class="btn btn-secondary" @click="idVerify()" :disabled="show.profile.idVerified == true || !init.FXCOMPLIANCE">Verify ID</button>
                            </div>
                          </div>
                          <div class="form-group d-flex">
                          </div>
                    
              </div>

        </div>
       
        
      
                       
      </div>
      <div class="modalBody" v-if="state == 'done'">
        <div >
          <h5 class="text-center">Decision has been updated</h5>
          <div class="d-flex">
            <button class="btn btn-main" @click="closeModal()">
              CLOSE
            </button>
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>

export default {
  props:["member"],
    components: {

    },
    data: function() {
        return {
            state:'init',
            idComment:'',
            poid:[],
            poidDesc:'VALID ID',
            poidData:{},
            viewFile: false,
            isVerified: false,
            errorMsg: ''

           
            
        }
    },
    computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        authorizedRepOnly(){
          return this.poid.filter(item => item.poidDesc !== this.poidDesc)
        },
       
     
      },
    methods: {
      goToProfile(){
        window.location.href='/#/customer-profile/' + this.$route.params.entityId
        },
      deletePoid(doc){
        this.poidData = doc
        const params = {
              eid: this.$route.params.entityId,
              poidKey: doc.poidKey
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/deleteCustomerPoid',
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data)
                 window.location.reload();
                 
              }).catch((err) => {
                  
              })
        
      },
      formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
      getPoid() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerPoids',
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data)
                 this.poid = response.data
                 
              }).catch((err) => {
                  
              })
          },
      getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile',
              }
              this.axios(options)
              .then((response) => {
               
                
              this.show.profile = response.data;
              this.show.corpDetails = response.data.corpDetails
              if( this.show.corpDetails.corpInfo != null){
                this.show.corpDetails.corpInfo = response.data.corpDetails.corpInfo
              }

             
             
              this.show.corpDetails.diretors.forEach((el,i)=>{
                this.profile = el
              })
              }).catch((err) => {
                  
              })
          },
      idVerify(){
        let hasError = false; 
        if(this.idComment == '' || this.idComment == undefined || this.idComment == null){
          hasError = true
          this.errorMsg = 'Please select your decision'
          setTimeout(()=>{
            this.errorMsg = "";
            hasError = false
            }, 3000)
        }
        
        if(!hasError){
        const params = {
            entityId: this.$route.params.entityId,
            isVerify:this.idComment
            }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: ' /ax/profile/verifyId'
              }
              this.axios(options)
              .then((response) => {
               window.location.reload();
               
              }).catch((err) => {
                  
              })
            }
      },
      idVerifyMember(){
        const params = {
            entityId: this.$route.params.entityId,
            isVerify:this.idComment,
            directorId: this.member.directorId
            }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/profile/corp/director/verifyId'
              }
              this.axios(options)
              .then((response) => {
               window.location.reload();
               
              }).catch((err) => {
                  
              })
            
      },
    
      cancel(){
        this.state='init'
      },
        closeModal(){
        this.$emit("close")
        window.location.reload();
        },

       
      
    },
      beforeMounted(){
        this.getProfile();
      },
    mounted(){
      this.getPoid();
      
        
        
    }
}
</script>

<style scoped lang=scss>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 1000px;
    height: auto;
    margin: 20px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    max-height: calc(100vh - 110px);
      overflow-y: auto;

    color:black;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:10px;
    border-radius: 10px;;
}

.btn-right{
  float: right;
  text-decoration: underline;
}
    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
    .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
    .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>