<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header">
            <h5 class="m-2 text-white">Rate {{ item.reviewId }} : {{ item.description }} for {{ $route.params.entityId }}</h5>
            <p @click="closeModal()" class="m-2 x">x</p>
        </div>
        
      <div class="modalBody" v-if="state == 'init'">
        <div v-if="isLoading" class="loading-spinner"></div>
        <div v-if="!isLoading">
          <div v-if="isMatched" class="text-center">
          <h5 class="text-center">The rate for this item has been submitted</h5>
          <button class="btn btn-primary" @click="closeModal()">Close</button>
        </div>
        <div class="market-content" v-if="!isMatched">
          <p v-if="error.show">{{ error.msg }}</p>
          <table class="table">
            <thead>
              <tr>
                <th>Rate</th>
                <th>Add remarks/ comments</th>
              </tr>
            </thead>
            <tbody>
              <td>
                <select v-model="rate" class="text-dark form-control form-select">
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
              </select>
              </td>
              <td>
                <input class="form-control" v-model="remarks" />
              </td>
            </tbody>
          </table>
          <button class="btn btn-primary" @click="submitReview()" :disabled="isSubmitting">Submit Rating</button>
        </div>
        </div>
       
        
      </div>
    <div class="modalBody" v-if="state == 'added'">
      <div >
        <h5 class="text-center">You added an item </h5>
        <div class="d-flex">
          <button class="btn btn-main" @click="closeModal()">
            CLOSE
           </button>
        </div>
      </div>
  </div>
    </div>
  </div>
</template>

<script>

export default {
  props:[
    "item"
  ],
    components: {

    },
    data: function() {
        return {
            state:'init',
            ratedReview:{},
            ratedReviewId:'',
            isMatched: false,
            ratedId:'',
            details:[],
            rate:'',
            remarks:'',
            isLoading: false,
            isSubmitting: false,
            error: {
              show: false,
              msg:''
            },
        }
    },
    computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       
     
      },
    methods: {
      submitReview(){
       
        let hasError = false;

        if(this.rate == '' || this.rate == undefined || this.rate == null){
          this.error.show = true
          this.error.msg = 'Please set your rating.'
          hasError = true

        }
        if(this.remarks == '' || this.remarks == undefined || this.remarks == null){
          this.error.show = true
          this.error.msg = 'Comment is required.'
          hasError = true

        }

        if(!hasError){
          const params = {
              reviewId: this.item.reviewId,
              rate: this.rate,
              remarks: this.remarks,
              entityId: this.$route.params.entityId
            }

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/rate'
              }
              this.axios(options)
              .then((response) => {
                this.isSubmitting = true;
                this.error.show = true
                this.error.msg = 'Your rate has been successfully submitted.'
                setTimeout( () => {
                  this.error.show = false
                  this.error.msg = ""
                  window.location.reload();
                  },2000)
              }).catch((err) => {
                this.error.show = true
                this.error.msg = err.response.data.msgText
                setTimeout( () => {
                  this.error.show = false
                  this.error.msg = ""
                        
                            
                  },3000)

              })

        }
            
      },
      getResult(){
        this.isLoading = true;
            this.ismatched = false;
            this.showRating = true;

            const params = {
              entityId: this.$route.params.entityId
            }
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/items/entityid',
              }
              this.axios(options) 
              .then((response) => {
                console.log(response.data)
                this.details = response.data
                console.log(this.details)
                this.isLoading = false

                let ratedReviewId = ''
                this.details.forEach((el, i) => {
                  this.ratedReview = el

                  ratedReviewId = this.ratedReview.reviewId

                  console.log(this.item.reviewId + '=' + ratedReviewId)

                    if(this.item.reviewId === ratedReviewId){
                      
                      this.isMatched = true
                      console.log(this.isMatched)
                      this.ratedReviewId = ratedReviewId
                      return;
                    } 
                })
                
              }).catch((err) => {
                  
              })
          },
    
      cancel(){
        this.state='init'
      },
        closeModal(){
        this.$emit("close")
        window.location.reload();
        },

       
      
    },
  
    mounted(){
        this.getResult();
        
    }
}
</script>

<style scoped lang=scss>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 20px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    max-height: calc(100vh - 110px);
      overflow-y: auto;

    color:black;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.userPic{
    width: 10rem;
}
.image{
  width: 100%;
}
.img img{
    width: 100%;
    border-radius: 10px;;
     box-shadow: 0 2px 2px rgba(50,50,50,.50);
     border:7px solid #fff;
     border-radius: 10px;;
     background-color: white;
      background-size:cover;
      object-fit: contain;
  }

  .info{
    width: 100%;
    margin-left: 5rem;
    padding-left:1rem;
      .info .name{
      padding: 0.5rem 0 2rem 0;
        .info .name h3{
        font-size: 1rem;
        line-height:110%;
        font-weight: 400;
          .info .name p{
          font-size:0.9rem;
          color: black;
          }
        }
      }
    .info .desc .soc-med{
      display: flex !important;
      flex-direction: row;
      justify-content: space-evenly;
      font-size: 15px !important;
    }
  }
.profile-card {
  height: auto !important;
  width: 100%;
  margin: auto 10px;
  background: Orgba(255,255,255,0.2);
  box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6);
  border: 4px white solid;
  padding: 5px;
  border-radius: 12px;
  position: relative;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
    &:hover{
    animation-name: bounce;
    animation-timing-function: ease;

    }

}
 

.iconImg {
    width:50%;
}
 .form-group{
        width: 100%;
    }

    .btn-main{
        margin: 30px auto;
        border: 1px solid #481a69;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
    .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>