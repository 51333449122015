import { render, staticRenderFns } from "./UploadProvisional.vue?vue&type=template&id=18f10c02&scoped=true&"
import script from "./UploadProvisional.vue?vue&type=script&lang=js&"
export * from "./UploadProvisional.vue?vue&type=script&lang=js&"
import style0 from "./UploadProvisional.vue?vue&type=style&index=0&id=18f10c02&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f10c02",
  null
  
)

export default component.exports