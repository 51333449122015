<template>
	<div class="main">
        
	<div class="main-container">
    <div v-if="isLoading" class="loading-spinner"></div>
              <div >
                <div class="market-content">
                  <table class="table">
                    <thead v-if="processingInvoiceOnly.length > 0 || accreditationInvoiceOnly.length > 0">
                      <tr>
                          <th>File ID</th>
                          <th>Type</th>
                          <th>Issued by</th>
                          <th>Date Issued</th>
                          <th v-b-tooltip.hover title="This will be filled out once the applicant has submitted the proof of payment">Proof of payment details</th>
                         
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="data in processingInvoiceOnly" :key="data.id">
                        <td >{{data.businessfileId}}</td>
                        <td>{{data.fileType}}</td>
                        <td>{{data.uploadedBy}}</td>
                        <td >{{formatDate(data.createDate)}}</td>
                       <td ><button class="btn-main" @click="uploadProofCeza" >Upload Proof of payment</button>
                      <button class="btn-main" :disabled="!isProofOfPaymentCz" @click="download(item)">
                        <span class="indicator" v-for="item in proofOfPaymentOnly" :key="item.id">
                        <img :src="require('../assets/circle/submitted.png')"/> 
                        </span>
                        download proof of payment
                      </button>
                      </td>
                      
                        <!-- <td >
                          <table class="inside-table">
                            <tbody v-for="item in proofOfPaymentOnly" :key="item.id">
                              <tr v-if="data.businessfileId == item.referenceFileId">
                                <td >
                                  {{item.description}}
                                </td>
                               
                                <td>
                                  <button class="btn btn-cancel" @click="download(item)" v-b-tooltip.hover title="Click to download the document">
                                    download
                                  </button>
                                </td>
                                <td>{{formatDate(item.createDate)}}</td>
                                <td class="d-flex" v-if="proofOfPaymentOnly.length > 0 && item.status == 'SUBMITTED'">
                                  <button class="btn btn-secondary" @click="approve(item)" :disabled="!init.FXCUSTPAYMENT" v-b-tooltip.hover title="Click to action the document">approve</button>
                                  <button class="btn btn-secondary" @click="approve(item)" :disabled="!init.FXCUSTPAYMENT" v-b-tooltip.hover title="Click to action the document">reject</button>
                                </td>
                                <td>{{ item.status }}</td>
                                <td v-if="item.status != 'SUBMITTED'">{{ item.remarks }}</td>
                                <td v-if="item.status != 'SUBMITTED'">actioned by: {{ item.processedBy }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td> -->
                        
                        
                        
                        <td v-if="proofOfPaymentOnly.length == 0"></td>
                      </tr>
                    </tbody>
              
                    <tbody>
                      <tr v-for="data in accreditationInvoiceOnly" :key="data.id">
                        <td >{{data.businessfileId}}</td>
                        <td>{{data.fileType}}</td>
                        <td>{{data.uploadedBy}}</td>
                        <td >{{formatDate(data.createDate)}}</td>
                        <td ><button class="btn-main" @click="uploadProofDt" >Upload Proof of payment</button>
                      <button class="btn-main" :disabled="!isProofOfPaymentDt" @click="download(item)">
                        <span class="indicator" v-for="item in proofOfPaymentOnlyA" :key="item.id">
                        <img :src="require('../assets/circle/submitted.png')"/> 
                        </span>
                        download proof of payment
                      </button>
                      </td>
                       
                        <!-- <td>
                          <table class="inside-table">
                            <tbody v-for="item in proofOfPaymentOnlyA" :key="item.id">
                              <tr v-if="data.businessfileId == item.referenceFileId">
                                <td >
                                  {{item.description}}
                                </td>
                               
                                <td>
                                  <button class="btn btn-cancel" @click="download(item)" v-b-tooltip.hover title="Click to download the document">
                                    download
                                  </button>
                                </td>
                                <td>{{formatDate(item.createDate)}}</td>
                                <td class="d-flex" v-if="proofOfPaymentOnlyA.length > 0 && item.status == 'SUBMITTED'">
                                  <button class="btn btn-secondary" @click="approve(item)" :disabled="!init.FXCUSTPAYMENT" v-b-tooltip.hover title="Click to action the document">approve</button>
                                  <button class="btn btn-secondary" @click="approve(item)" :disabled="!init.FXCUSTPAYMENT" v-b-tooltip.hover title="Click to action the document">reject</button>
                                </td>
                                <td>{{ item.status }}</td>
                                <td v-if="item.status != 'SUBMITTED'">{{ item.remarks }}</td>
                                <td v-if="item.status != 'SUBMITTED'">actioned by: {{ item.processedBy }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td> -->
                        
                        <!-- <td class="d-flex" v-if="proofOfPaymentOnlyA.length > 0 && data.status == 'SUBMITTED'">
                          <button class="btn btn-secondary" @click="approve(data)" :disabled="!init.FXCUSTPAYMENT">approve</button>
                          <button class="btn btn-secondary" @click="approve(data)" :disabled="!init.FXCUSTPAYMENT">reject</button>
                        </td> -->
                        <td v-if="proofOfPaymentOnlyA.length > 0 && data.status == 'REJECT'"></td>
                        <td v-if="proofOfPaymentOnlyA.length == 0"></td>
                      </tr>
                    </tbody>
                    
                  </table>
                  </div>
                  </div>
              <div class="">
                  <ul class="btn-group-card" >
                    <div v-if="isLoading" class="loading-spinner"></div>
                    <form >
                    <li class="d-flex" >
                      <button   class="btn-card-upload" @click.prevent="uploadProcessing()" :disabled="!init.FXCUSTPAYMENT" v-b-tooltip.hover title="Click to issue an invoice. To be actioned by CEZA team">
                        <h5>Issue Payment Order Invoice (CEZA)
                         
                      </h5>
                      </button>
                      <button class="btn-card-upload" @click.prevent="uploadAccreditation()" :disabled="!init.FXCUSTPAYMENT" v-b-tooltip.hover title="Click to issue an invoice. To be actioned by DigitalTrust team">
                        <h5>Issue an Probity Checking and Integration Invoice (Digital Trust)
                          <span class="indicator" v-if="isApproved"  >
                          <img :src="require('../assets/circle/submitted.png')"/> 
                        </span>
                      </h5>
                      </button>
                    </li>
                </form>
                </ul>



                <div v-if="show.profile.signupHost == 'gaming.ceza.org'"> 
                  <div v-if="init.tokens.length == 4">
                    <p >To be confirmed by the Approver</p>
                    <div >
                      <button class="btn btn-approve" @click.prevent="openUploadApprover('5')" v-b-tooltip.hover title="To be confirmed by the final approver">
                        Confirm 
                      </button>
                    </div>
                </div>
                <div v-if="init.numberOfTokens >= 5">
                  <ul v-for="data in init.tokens" :key="data.id">
                    <li v-if="data.step == '5'">
                     <h5>Confirmed on <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank" class="link">blockchain</a> by: {{ data.issuedBy }} on {{ formatDate(data.updateDate) }}</h5> 
                     <!-- <button class="btn btn-secondary"> <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank">view on blockchain</a></button> -->
                    </li>
                  </ul>
                   
              </div>
                </div>
                <div v-if="show.profile.signupHost == 'fintech.ceza.org'">
                  <div v-if="init.tokens.length == 3">
                    <p >To be confirmed by the Approver</p>
                    <div >
                      <button class="btn btn-approve" @click.prevent="openUploadApprover('4')" v-b-tooltip.hover title="To be confirmed by the final approver">
                        Confirm 
                      </button>
                    </div>
                </div>
                <div v-if="init.numberOfTokens >= 4">
                  <ul v-for="data in init.tokens" :key="data.id">
                    <li v-if="data.step == '4'">
                     <h5>Confirmed on <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank" class="link" v-b-tooltip.hover title="Click to view and verify on the blockchain">blockchain</a> by: {{ data.issuedBy }} on {{ formatDate(data.updateDate) }}</h5> 
                     <!-- <button class="btn btn-secondary"> <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank">view on blockchain</a></button> -->
                    </li>
                  </ul>
                   
              </div>
                </div>

                
                
              </div>
        </div>
        
    <UploadAccreditationInvoice v-if="openUploadAccreditation" @close="uploadAccreditation()"></UploadAccreditationInvoice>
    <UploadProcessingInvoice v-if="openUploadProcessing" @close="uploadProcessing()"></UploadProcessingInvoice>
    <ViewUpload v-if="openApprove" @close="approve(data)" :data="dataInfo"></ViewUpload>
    <FinalApprove v-if="showUploadApprover" @close="openUploadApprover()" :step="step"></FinalApprove>
    <UploadProofOfPaymentCZ v-if="openProofCeza" @close="uploadProofCeza()"></UploadProofOfPaymentCZ>
    <UploadProofOfPaymentDT v-if="openProofDt" @close="uploadProofDt()"></UploadProofOfPaymentDT>
  </div>
  
 
          
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import UploadAccreditationInvoice from '../components/modal/Documents/UploadAccreditationInvoice.vue'
  import UploadProcessingInvoice from '../components/modal/Documents/UploadProcessingInvoice.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
  import FinalApprove from '../components/modal/Documents/FinalApprove.vue'
  import UploadProofOfPaymentCZ from '../components/modal/Documents/UploadProofOfPaymentCZ.vue'
  import UploadProofOfPaymentDT from '../components/modal/Documents/UploadProofOfPaymentDT.vue'
  export default {
      name: 'Payments',
      components: {
        TheTopbar,
        Header,
        UploadAccreditationInvoice,
        UploadProcessingInvoice,
        ViewUpload,
        FinalApprove,
        UploadProofOfPaymentCZ,
        UploadProofOfPaymentDT
      },
      
       data: function() {
      return { 
        showUploadApprover: false,
        isApproved: false,
        showUpload: false,
        accrediationInvoice:'DIGITALTRUST-INVOICE',
        processingInvoice: 'PAYMENT-ORDER-INVOICE',
        processing: 'PROOF OF PAYMENT-ORDER',
        accreditation: 'PROOF OF PAYMENT-DIGITALTRUST',
        openUploadProcessing: false,
        openUploadAccreditation: false,
        openApprove: false,
        openReject: false,
        dataInfo:{},
        imageUrl:'',
        fileType:'',
        step: '',
        isLoading: false,
        noOfInvoiceAccr:'',
        noOfInvoiceProcess:'',
        openProofCeza: false,
        openProofDt: false,
        isProofOfPaymentDt: false,
        isProofOfPaymentCz: false,
        isApprovedByCeza: false,
        isApprovedByDigital: false,
        proofPayment: ''
        

      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        processingInvoiceOnly(){
          return this.init.list.documentData.filter(data => data.fileType === this.processingInvoice)
        },
        accreditationInvoiceOnly(){
          return this.init.list.documentData.filter(data => data.fileType === this.accrediationInvoice)
        },
        proofOfPaymentOnly(){
          return this.init.list.documentData.filter(data => data.fileType === this.processing)
        },
        proofOfPaymentOnlyA(){
          return this.init.list.documentData.filter(data => data.fileType === this.accreditation)
        },
     
      },
      methods:{
        uploadProofCeza(){
          this.openProofCeza = !this.openProofCeza
        },
        uploadProofDt(){
          this.openProofDt = !this.openProofDt
        },
        openUploadApprover(step){
          this.step = step
          this.showUploadApprover = !this.showUploadApprover
        },
        uploadProcessing(){
          this.openUploadProcessing = !this.openUploadProcessing
        },
        uploadAccreditation(){
          this.openUploadAccreditation = !this.openUploadAccreditation
        },
        getPoid(){
          this.isLoading = true
          const params = {
            entityId: this.$route.params.entityId,
           
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/'
              }
              this.axios(options)
              .then((response) => {
                this.isLoading = false
            
                this.init.list.documentData = response.data

                this.init.list.documentData.forEach((el, i) => {
                  // if(el.fileType == 'PROOF OF PAYMENT-ORDER'){
                  //   console.log('this.isProofOfPaymentCz')
                  // }
                  if(el.fileType === 'PROOF OF PAYMENT-ORDER'){
                    this.isProofOfPaymentCz = true
                   if(el.status == 'ACCEPTED'){
                      this.isApprovedByCeza = true
                    }
                   
                  }
                  if(el.fileType === 'PROOF OF PAYMENT-DIGITALTRUST'){
                    this.isProofOfPaymentDt = true
                   if(el.status == 'ACCEPTED'){
                      this.isApprovedByDigital = true
                    }
                  }

                })

                
               
              }).catch((err) => {
                  
              })
        },
       
      download(item){
        this.imageUrl = item.imageFile
        this.fileType = this.imageUrl.split('.').pop();

           const params = {
            entityId: this.$route.params.entityId
        }
           
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
                params,
                url: '/ax/customer/businessfile/image/' + item.businessfileId,
                }
                this.axios(options)
                .then((response) => {
                  
                  
                if(this.fileType === 'pdf'){
                  const link = document.createElement('a');
                  link.href = "data:application/pdf;base64," + response.data
                  link.download = 'proof of payment'; 
                  link.click();
                }

                if(this.fileType !== 'pdf'){
                  const link = document.createElement('a');
                  link.href = "data:image/png;base64," + response.data
                  link.download = 'proof of payment'; 
                  link.click();
                }
               
                
                }).catch((err) => {
                    
                })
          },
       
       
          formatDate: function(date) {
            return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
          },
          approve(data){
            this.dataInfo = data
            this.openApprove = !this.openApprove
          },
          reject(data){
            this.dataInfo = data
            this.openReject = !this.openReject
          },
         
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile/',
              }
              this.axios(options)
              .then((response) => {
                
                this.show.profile = response.data;
                this.show.corpDetails = response.data.corpDetails
                
              
              }).catch((err) => {
                  
              })
          },
        
          
      
          
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
        this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getPoid();
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
    color: white;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  
    .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #FFFFFF; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
      //       background-image: linear-gradient(
      //       to bottom,
      //       #202538 29%,
      //       #10121C 88%
      // );
            text-align: inherit;
          }
        }
  
        tbody {
      //     background-image: linear-gradient(
      //       to bottom,
      //       #202538 29%,
      //       #10121C 88%
      // );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
   
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
 
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}
.btn-approve{
       width: 100%;
       background-color: green !important;
        color: white;
    }
.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-color: #b47e27 !important;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #053d5c !important;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}

</style>
