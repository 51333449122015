import { render, staticRenderFns } from "./WatchlistMember.vue?vue&type=template&id=3552c817&scoped=true&"
import script from "./WatchlistMember.vue?vue&type=script&lang=js&"
export * from "./WatchlistMember.vue?vue&type=script&lang=js&"
import style0 from "./WatchlistMember.vue?vue&type=style&index=0&id=3552c817&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3552c817",
  null
  
)

export default component.exports