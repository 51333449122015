<template>
	<div class="main">
     
	        <div class="main-container">
            <div v-if="isLoading" class="loading-spinner"></div>
            <div class="market-content"> 
              <table  v-for="item in licenseFile" :key="item.id" class="table">
               <thead v-if="item.step == '8'"> 
                <th>Date</th>
                <th>Issued By</th>
                <th>License Number</th>
                <th>Principal Licensee</th>
                <th>Expiration Date</th>
                <th>Txn Hash</th>
               </thead>
               <tbody v-if="item.step == '8'">
                 <td>{{ formatDate(item.createDate) }}</td>
                 <td>{{ item.issuedBy }}</td>
                 <td>{{ item.licenseNumber }}</td>
                 <td>{{ item.principalLicensee }}</td> 
                 <td>{{ formatDate(item.expirationDate) }} 
                  <span v-if="item.expiring == true"><button class="btn btn-danger" @click="notify()">Notify licensee <b-bdage v-b-tooltip.hover title="Click to notify and send an email to the applicant about the expiring license">i</b-bdage></button></span>
                </td> 
                 <td><a :href="'https://explorer.blxq.org/token/' + item.hash" target="_blank">{{ item.hash }}</a></td>
                 
               </tbody>
             </table>
            </div>
            <ul v-for="item in fullLicenseOnly" :key="item.id"> 
              <li>
                <button class="btn btn-primary text-white"  @click.prevent="viewUpload(item)" v-b-tooltip.hover title="Click to view and download the issued full license">view</button>
              </li>
            </ul>
           
            <button class="btn-card-upload" @click.prevent="uploadLicense()" :disabled="!init.FXCUSTISSUECERTIFICATE" v-if="fullLicenseOnly.length == 0" v-b-tooltip.hover title="Click to issue a Full License">
                      <h5>Issue Full License
                        <span class="indicator" v-if="isApproved"  >
                        <img :src="require('../assets/circle/submitted.png')"/> 
                      </span>
                    </h5>
              </button>
             
              <!-- <div v-if="init.numberOfTokens== 8">
                    <p >To be confirmed by the Approver</p>
                    <div >
                      <button class="btn btn-approve" @click.prevent="openUploadApprover('6')" :disabled="!init.FXSIGN">
                        Confirm 
                      </button>
                    </div>
                </div> -->
        </div>
        
    <upload-full-license v-if="openUpload" @close="uploadLicense()"></upload-full-license>
    <issue-license v-if="showUpload" @close="viewUpload(dataInfo)" :data="dataInfo" :format="item.fileType"></issue-license >
    <FinalApprove v-if="showUploadApprover" @close="openUploadApprover('1')" :step="step"></FinalApprove>
  
  </div>
  
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import IssueLicense  from '../components/modal/Documents/IssueLicense.vue'
  import FinalApprove from '../components/modal/Documents/FinalApprove.vue'
import UploadFullLicense from '../components/modal/Documents/UploadFullLicense.vue';

  export default {
      name: 'FullLicense',
      components: {
        TheTopbar,
        Header,
        IssueLicense,
        FinalApprove,
        UploadFullLicense
      },
     
       data: function() {
      return { 
          isLoading: false,
          isApproved: false,
          isRejected: false,
          showUpload: false,
          openUpload: false,
          showUploadApprover: false,
          status:'',
          fileType:'FULL-LICENSE',
          item:{
            fileType:''
          },
          step: '8',
          month:'',
          day:'',
          year:'',
          licenseeNumber:'',
          principalLicensee: '',
          days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() + i),
          businessfileId:'',
          licenseFile:[]
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        fullLicenseOnly(){
          return this.init.list.documentData.filter(data => data.fileType === this.fileType)
        },
       
      },
      methods:{
        notify(){
          this.isLoading = true;
          const params = {
            step: this.step,
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/businessfile/sendnotif' 
              }
              this.axios(options)
              .then((response) => {
                this.isLoading = false
               
              }).catch((err) => {
                  
              })
        },
        updateDate() {
          if (this.day && this.month && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
        getLicenseFile(){
          const params = {
            entityId: this.$route.params.entityId,
           
            }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/businessfile/token/list'
              }
              this.axios(options)
              .then((response) => {
                
                this.licenseFile = response.data
               
              }).catch((err) => {
                  
              })
        },
        uploadLicense(){
          this.openUpload = !this.openUpload
        },
        openUploadApprover(step){
          this.step = step
          this.showUploadApprover = !this.showUploadApprover
        },
         getBusinessFile(){
          this.isLoading = true
          const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/' 
              }
              this.axios(options)
              .then((response) => {
                this.isLoading = false
                this.init.list.documentData = response.data

                this.init.list.documentData.forEach(el, value => {
                  if(el.description == 'FULL-LICENSE'){
                    this.businessfileId = el.businessfileId
                  }
                })

                let item = [];
                this.init.list.documentData.forEach((el, i) => {
                  item.push(el);
                  this.imgUrl = el.imageFile
                  });
                  this.item.fileType = this.imgUrl.split('.').pop();
                  console.log(this.item.fileType)
               
              }).catch((err) => {
                  
              })
        },
        viewUpload(docData){
          this.dataInfo = docData
          this.showUpload = !this.showUpload
        },
      
        formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
         
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile/',
              }
              this.axios(options)
              .then((response) => {
                
                this.show.profile = response.data;
                this.show.corpDetails = response.data.corpDetails
                
              
              }).catch((err) => {
                  
              })
          },
       

        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
              this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
     
      mounted(){
          this.sessionCheck();
          this.getBusinessFile();
          this.getLicenseFile();
          
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
   ;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .placeholder-circle {
    width: 25px;
    height: 25px;
    border: 1px solid #ccc; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  .approved-circle{
    width: 25px;
    height: 25px;
    background-color: #c69903; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 200px;
    height: 50px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 10px;
    white-space: wrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
    padding: 5px 10px; /* Adjust the padding as needed to fit text */
    max-width: 100%; /* Ensure the button doesn't grow beyond its container */
    font-size: 10px; /* Adjust the font size as needed */
    color: black;
    }
    .market-content {
  width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 450px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.btn-approve{
       width: 100%;
       background-color: green !important;
        color: white;
    }

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}
.button{
  background-color: transparent !important;
  border: transparent !important;
}
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
 
  


@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #053d5c !important;
  display: flex;
  justify-content: space-between;
  color: white;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}

</style>
