<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
          
            <h5 class="m-2 text-white">Confirm step {{step}} to proceed to the next step</h5>
            <p @click="closeModal()" class="m-2 x">x</p>
        </div>
      
      <div class="modalBody" v-if="state == 'init'">
          <div >
            <p style="color: rgb(199, 71, 71);" v-if="showErrorMsg">{{errorMsg}}</p>
            <h5 class="text-center">Are you sure you want to CONFIRM this?</h5>
           
            <div class="d-flex">
              <button class="btn btn-main" @click="closeModal()">
                CANCEL
               </button>
              <button class="btn btn-main" @click="confirmApproved()">
                Confirm
               </button>
            </div>
            
          </div>
      </div>
      
    <div class="modalBody" v-if="state == 'approved'">
      <div >
        <h5 class="text-center">You have approved this step. </h5>
        <div class="d-flex">
          <button class="btn btn-main" @click="closeModal()">
            CLOSE
           </button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'

export default {
    props:["step"],
    components: {

    },
    data: function() {
        return {
            state:'init',
            imgUpload:'',
            isLoading: false,
            comment:'',
            showErrorMsg: false,
            errorMsg: '',
            image:''
           
            
        }
    },
    computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       
     
      },
    methods: {
      showUpload(){
        this.isLoading = true;
        const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/image/' + this.data.businessfileId,
              }
              this.axios(options)
              .then((response) => {
               console.log(response.data)
               this.isLoading = false;
               this.imgUpload = "data:application/pdf;base64," + response.data
                this.image = response.data
                
              }).catch((err) => {
                  
              })
      },
      approve(){
        this.state = "confirmApprove"
      },
      reject(){
        this.state = "confirmReject"
      },
      // confirmApproved(){
      //     const params = {
      //     businessfileId: this.data.businessfileId,
        
      //   }
           
      //       const options = {
      //         method: 'POST',
      //         headers: {
      //             'content-type': 'application/x-www-form-urlencoded',
      //             'vmiadmin-authz': localStorage.getItem("tokenId") 
      //         }, 
      //         params,
      //         url: '/ax/businessfile/confirm',
      //         }
      //         this.axios(options)
      //         .then((response) => {
      //          console.log(response.data)
      //           this.state = 'approved'
          
                
      //         }).catch((err) => {
                  
      //         })
        
      // },
      confirmApproved(){
          const params = {
          entityId: this.$route.params.entityId,
          step: this.step
        
        }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/businessfile/confirm',
              }
              this.axios(options)
              .then((response) => {
               console.log(response.data)
                this.state = 'approved'
          
                
              }).catch((err) => {
                this.showErrorMsg = false;
                this.errorMsg = err.response.data.msgText
                  
              })
        
      },
      confirmRejected(){
        let hasError = false;
        if(this.comment == '' || this.comment == undefined || this.comment == null){
          this.showErrorMsg = true
          this.errorMsg = 'Comment/ Remarks is required.'
          hasError = true
          setTimeout(()=>{
            this.showErrorMsg = false;
            this.errorMsg = ''
          }, 2000)
        }

        if(!hasError){
          const params = {
          businessfileId: this.data.businessfileId,
          remarks: this.comment
        }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/businessfile/reject',
              }
              this.axios(options)
              .then((response) => {
               console.log(response.data)
                this.state = 'rejected'
          
                
              }).catch((err) => {
                  
              })
        }
        
        
      },
     
        closeModal(){
        this.$emit("close")
        window.location.reload();
        },

       
      
    },
  
    mounted(){
        // this.showUpload();
        
        
    }
}
</script>

<style lang=scss scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 20px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    max-height: calc(100vh - 110px);
      overflow-y: auto;

    color:black;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}
.btn-main{
        background-size:0% 100%;
        background-repeat:no-repeat;
        width:100%;
        border-radius: 10px;
        border-color: #0c3e66 !important;
        color:black !important;
      }
  .btn-main:hover{
      background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
	    background-size:100% 100%;
    	color:black !important;
  }

 

.iconImg {
    width:50%;
}
 .form-group{
        width: 100%;
    }

  


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
    .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>