<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">Namescreen Assessment Verification for Member {{ member.firstName }} {{ member.lastName }}</h5>
            <p @click="closeModal()" class="m-2 x">x</p>
        </div>
      <div class="modalBody" v-if="state == 'init'">
        <div class="market-content">
                    <table class="table">
                      <thead>
                      <tr>
                          <th>Match Type
                            <b-button v-b-tooltip.hover title="Used to determine how closely a watchlist entity must match an individual before being considered a match">
                          </b-button>
                          </th>
                          <th v-if="matchType == 'Close'">Match Rate %
                            <b-button v-b-tooltip.hover title="1-100%">
                          </b-button>
                          </th>
                          <th>Whitelist Policy
                            <b-button v-b-tooltip.hover title="Used for eliminating match results previously determined to not be a true a match">
                          </b-button>
                          </th>
                          <th>Residence Policy
                            <b-button v-b-tooltip.hover title="Used for eliminating match results previously determined to not be a true a match">
                          </b-button>
                          </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                        <td>
                          <select v-model="matchType" class="form-select form-control">
                            <option value="Exact">Exact</option>
                            <option value="ExactMidName">Exact (Incl Mid Name)</option>
                            <option value="Close">Close</option>
                          </select>
                        </td>
                        <td v-if="matchType == 'Close'">
                          <select v-model="closeMatchRateThreshold" class="form-select form-control">
                            <option value="">match rate</option>
                            <option v-for="rateOption in rates" :key="rateOption" :value="rateOption">{{ rateOption }}</option>
                    
                          </select>
                        </td>
                        <td>
                          <select v-model="whitelist" class="form-select form-control">
                            <option value="true">Apply</option>
                            <option value="false">Ignore</option>
                           
                          </select>
                        </td>
                        <td>
                          <b-form-group label="Select multiple:" v-slot="{ ariaDescribedby }" class="text-white">
                            <b-form-checkbox-group @change="handleSelectChange" @select="optionSelected"
                              id="checkbox-group-1" class="text-white"
                              v-model="residences"
                              :options="options"
                              :aria-describedby="ariaDescribedby"
                              
                            ></b-form-checkbox-group>
                          </b-form-group>
                          {{ residences }}
                        </td>
                       
                      </tr>
                    </tbody>
                    </table>
                      <table class="table">
                      <thead>
                      <tr>
                        <th>PEP Jurisdiction
                            <b-button v-b-tooltip.hover title="Used for eliminating match results where the country of jurisdiction of the matching entity is specified in the organisation's settings (Exclude PEP Jurisdiction).">
                          </b-button>
                          </th>
                        <th>Exclude Deceased Person
                            <b-button v-b-tooltip.hover title="Used for eliminating match results where the person is tagged as deceased.">
                          </b-button>
                          </th>
                          <th>Web Search
                            <b-button v-b-tooltip.hover title="Additional search for adverse media on the web using Google search engine.">
                          </b-button>
                          </th>
                          <th>FATF Jurisdiction Risk
                            <b-button v-b-tooltip.hover title="Understand the jurisdictional risk of an individual based on FATF guidelines.">
                          </b-button>
                          </th>
                          <th>Watchlist
                            <b-button v-b-tooltip.hover title="Scan against selected watchlists. This selection can be changed by the Compliance Officer in Administration > Organisations > List Access tab.">
                          </b-button>
                          </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                        <td>
                          <select v-model="pepJurisdiction" class="form-select form-control">
                            <option value="true">Exclude: none</option>
                            <option value="false">Ignore</option>
                          </select>
                        </td>
                        
                        <td>
                          <select v-model="excludeDeceasedPersons" class="form-select form-control">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td>
                        <td>
                          <select v-model="includeWebSearch" class="form-select form-control">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td>
                        <td>
                          <select v-model="includeJurisdictionRisk" class="form-select form-control">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td>
                        <td>
                          PEP | POI | RCA | SIP - Terror, Organised Crime, Financial Crime, Narcotics Crime, Modern Slavery, Bribery and Corruption, Cyber Crime, Disqualified Directors, Other, Sanctions Lists (All), Law Enforcement, Regulatory Enforcement, Reputational Risk
                        </td>
                      </tr>
                    </tbody>
                    </table>
                  </div>

                   
                  <div class="market-content">
                    <p style="color: red" v-if="status == '400'">{{ errorMsg }}</p>
                    <table class="table" >
                      <thead>
                      <tr>
                          <th>First Name</th>
                          <th>Middle Name</th>
                          <th>Last Name</th>
                        
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                        <td>
                          <input class="form-control" v-model="member.firstName"/>
                        </td>
                        <td>
                          <input class="form-control" v-model="member.middleName"/>
                        </td>
                        <td>
                          <input class="form-control" v-model="member.lastName"/>
                        </td>
                       
                      </tr>
                    </tbody>
                    </table>
                    <table class="table">
                      <thead>
                      <tr>
                        <th>Date of Birth (DD/MM/YYYY)</th>
                        <th>Gender</th>
                        <th>Country of Residence</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                     
                        <td >
                      <select v-model="member.birthDd" @change="updateDate" class="form-control-short">
                        <option value="">DD</option>
                        <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
                      </select>
                    
                      <select v-model="member.birthMm" @change="updateDate" class="form-control-short">
                        <option value="">MM</option>
                        <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                      </select>
                    
                      <select v-model="member.birthYear" @change="updateDate" class="form-control-short">
                        <option value="">YYYY</option>
                        <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                      </select>
                    </td>
                      
                        <td >
                          <select v-model="member.gender" class="form-select form-control">
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                        </td>
                        <td >
                          <div class=" mobile-container">
                            <div class="input-group mb-3">
                              <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="acountry" v-on:click="toggleCountryListForAddress" >
                            </div>
                            <div class="country-list" v-if="showCountryForAddress">
                              <div class="row">
                                <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" >
                                  <div>
                                    <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                                  </div>
                                  <div class="country-name">
                                    <span> {{ l.country }} </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        
                      </tr>
                    </tbody>
                    </table>
                    <div> 
                      <p v-if="showErrorMsg"> {{ errorMsg }}</p>
                    </div>
                    <div>
                    <button class="btn btn-primary" @click="scanProfile()" :disabled="isLoading">Scan</button>
                    <div v-if="isLoading" class="loading-spinner"></div>
                  </div>
                  </div>
                   
                       
      </div>
      <div class="modalBody" style="text-align: center;" v-if="state == 'results'">
       
        <div>
          <h5>Results for {{ member.firstName }} {{ member.lastName }}</h5>
          <div v-if="showErrorMsg">
            <p  >{{ errorMsg }}</p>
          </div>

            <div v-if="showResponseResult">
              <div class="options-bar">
                <div class="options">
                <button class="btn btn-main" :class="{ active: resultState == 'fatf'}" @click="changeState('fatf')">FATF Jurisdiction Results</button>
                <button class="btn btn-main" :class="{ active: resultState == 'matched'}" @click="changeState('matched')">Matched Entities</button>
             
              </div>
            </div>
            
                  <div class="market-content" v-if= "resultState == 'fatf'">
                      <table>
                        <thead>
                          <th>Comments</th>
                          <th>Compliance Level</th>
                          <th>Compliance Score</th>
                          <th>Country</th>
                          <th>Effectiveness Level</th>
                          <th>Effectiveness Score</th>
                          <th>FAT Compliance</th>
                          <th>FAT Effectiveness </th>
                        </thead>
                        <tbody >
                          <tr v-if="memberScan.fatfJurisdictionRiskResults == ''">
                            <td colspan="8">No data found</td>
                          </tr>
                          <tr v-else v-for="item in memberScan.fatfJurisdictionRiskResults" :key="item.id">
                            <td>{{item.comments}}</td>
                            <td>{{item.complianceLevel}}</td>
                            <td>{{item.complianceScore}}</td>
                            <td>{{ item.countryCode }}</td>
                            <td>{{item.effectivenessLevel}}</td>
                            <td>{{item.effectivenessScore}}</td>
                            <td>{{item.fatfCompliance}}</td>
                            <td>{{ item.fatfEffectiveness }}</td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <br>
                      <div class="market-content" v-if="resultState == 'matched'">
                        <div> <p>{{ memberScan.metadata.message }}</p> </div>
                        <button class="btn btn-primary" @click="viewScanResult(memberScan)" v-if="memberScan.matchedNumber > 0">view more info</button>
                      <table v-if="memberScan.matchedNumber > 0">
                        <thead>
                          <tr>
                          <th>Category</th>
                          <th>First Name</th>
                          <th>Last Name </th>
                          <th>Match Rate</th>
                          <th>Primary Location</th>
                       
                         </tr>
                         
                        </thead>
                        <tbody v-for="item in memberScan.matchedEntities" :key="item.id">
                          <tr >
                            <td>{{item.category}}</td>
                            <td>{{item.firstName}}</td>
                            <td>{{item.lastName}}</td>
                            <td>{{ item.matchRate }}</td>
                            <td>{{item.primaryLocation}}</td>
                          </tr>
                        </tbody>
                      </table>
                   
                </div>
           
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>


export default {
  props:["member"],
    components: {

    },
    data: function() {
        return {
            state:'init',
            matchType:'Close',
            closeMatchRateThreshold:'80',
            whitelist:false, 
            residences: [],
            residenceValue:'',
            options: [
                      { text: 'Apply PEP,', value: 'ApplyPEP'},
                      { text: 'Apply SIP,' , value: 'ApplySIP'},
                      { text: 'Apply RCA,' , value: 'ApplyRCA'},
                      { text: 'Apply POI,' , value: 'ApplyPOI'},
                      { text: 'Apply All,' , value: 'ApplyAll'},
                      { text: 'Ignore,' , value: 'Ignore'},
                      
                    ],
            excludeDeceasedPersons: false,
            pepJurisdiction: true,
            includeWebSearch: false,
            includeJurisdictionRisk: true,
            rates: Array.from({ length: 100 }, (v, i) => (i + 1).toString().padStart(2, '0')),
            days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
            month:'',
            day:'',
            year:'',
            showErrorMsg:false,
            status:'',
            acountry:'',
            showCountryForAddress: false,
            isLoading: false,
            isScanDone: false,
            memberScan: [],
            scanId:'',
            showResponseResult: false,
            resultState:'matched',
            list:{
              mobileCodes:[]
            }
            
        }
    },
    computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       
     
      },
    methods: {
      changeState(resultState) {
            this.resultState = resultState;
         
          },
      handleSelectChange() {
          if (this.residences.includes('ApplyAll')) {
            this.residences = ['ApplyAll'];
          }
          if (this.residences.includes('Ignore')) {
            this.residences = ['Ignore'];
            return;
          }
          
        },
        
        optionSelected(option, id) {
          this.residences = option.value
          this.residenceValue.push(this.residences)
        },
        updateDate() {
            if (this.day && this.month && this.year) {
              // Do any additional validation or formatting if needed
              const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
           
            }
          },
          toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.acountry = c.countryCode
           
          },
         
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {

              })
          },

          scanProfile(){
            this.isLoading = true
              let hasError = false;
                if(this.member.firstName == '' || this.member.firstName == undefined || this.member.firstName == null){
                  this.showErrorMsg = true
                  this.errorMsg = 'First Name is required.'
                  hasError = true
                  setTimeout(()=>{
                    this.showErrorMsg = false,
                    this.errorMsg = ''
                  }, 2000)
                }
                if(this.member.lastName == '' || this.member.lastName == undefined || this.member.lastName == null){
                  this.showErrorMsg = true
                  this.errorMsg = 'Last Name is required.'
                  hasError = true
                  setTimeout(()=>{
                    this.showErrorMsg = false,
                    this.errorMsg = ''
                  }, 2000)
                }

              
                this.isLoading = true
                this.isScanDone = false
                this.residences.forEach((el,i) => {
                this.residenceValue = el.value
              })
                
              let birthdate = ''
              if (this.member.birthDd == undefined && this.member.birthMm == undefined && this.member.birthYear == undefined) {
                  birthdate = ""
                } else {
                  birthdate = this.member.birthDd + '/' + this.member.birthMm + '/' + this.member.birthYear
               }
               if(!hasError){
                const params = {
                firstName: this.member.firstName,
                middleName: this.member.middleName,
                lastName: this.member.lastName,
                gender: this.member.gender,
                dob: birthdate,
                country: this.acountry,
                matchType: this.matchType,
                closeMatchRateThreshold: this.closeMatchRateThreshold,
                whitelist: this.whitelist,
                residence: this.residenceValue,
                excludeDeceasedPersons: this.excludeDeceasedPersons,
                pepJurisdiction: this.pepJurisdiction,
                includeWebSearch: this.includeWebSearch,
                includeJurisdictionRisk: this.includeJurisdictionRisk,
                clientId: this.member.firstName + this.member.lastName

            }

            
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/namescan/scan',
              }
              this.axios(options)
              .then((response) => {
                    this.isLoading = false
                    this.isScanDone = true
                    this.state = 'results'
                    if(response.data.matchedNumber >= 0){
                      this.showResponseResult = true
                      this.memberScan = response.data
                      this.message = response.data.metadata.message
                      this.scanId = response.data.scanId
                    }
                  if(response.data.errorType != ''){
                    this.showErrorMsg = true
                    this.errorMsg = response.data.message
                  } 
                 
                  

                if(response.data.status == 400){
                  this.showErrorMsg = true
                  this.errorMsg = response.data.errors
                  setTimeout(()=>{
                    this.errorMsg = ""
                  }, 2000)
                }
                
                 
              
                
                // setTimeout(()=>{
                //      window.location.reload();
                //   }, 2000)
              }).catch((error) => {
                this.errorMsg = response.data.title
              })
              }
            },
           
     
      cancel(){
        this.state='init'
      },
        closeModal(){
        this.$emit("close")
        window.location.reload();
        },
        getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile/',
              }
              this.axios(options)
              .then((response) => {
                
                this.show.profile = response.data;
                this.show.corpDetails = response.data.corpDetails
              }).catch((err) => {
                  
              })
          },
          viewScanResult(memberScan){
          this.scanId = memberScan.scanId
          window.location.href="/#/name-matches/" + this.scanId
        },
      
    },
  
    mounted(){
        this.getProfile();
        this.getMobileCodes();
        
    }
}
</script>

<style scoped lang=scss>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    min-width: 800px;
    height: auto;
    margin: 20px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    max-height: calc(100vh - 110px);
      overflow-y: auto;

    color:black;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}
.options{
    width: 100%;
}
.options-bar{
display: flex;
justify-content: space-between;
}
.options button{
    width: auto;
    padding: 10px;
    margin: 5px;
    border: none !important;
    color: rgb(11, 11, 11) !important;
    border-radius: 10px;
    border: 1px #007bff solid !important;
    
}
.options button:hover{
    background-color:#007bff!important;
    border: 1px #007bff solid !important;
    color: rgb(255, 255, 255) !important;
}
.options button.active{
    background-color:#007bff !important;
    border: 1px #007bff solid !important;
   
}
.mobile-container {
          position: relative;  
      }
      .default-flag {
          width: 33px;
          margin-right:0.25em;
      }
      .btn{
          border: 1px solid #1a2169;
          
      }
      .country-list {
          position: absolute;
          background: #ccc;
          color: black;
          top: 70px;
          padding: 0.5em;
          height: 13em;
          overflow: auto;
          z-index: 3;
      }
      .country-list .row {
          margin: 0 !important;
      }
  
      .country-list .col-sm-6 {
        display: flex;
        padding: 0.7em;
        align-items: center;
        cursor: pointer;
      }
      
      .country-name {
        margin-left: 0.5em;
      }
      .country-name span {
          font-size: 0.9em;
      }
  
      .flag-list-img {
          width: 25px;
      }
.form-control-short{
    border-radius: 10px;
    padding: 10px;
  }
.btn-right{
  float: right;
  text-decoration: underline;
}
    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
      

    }

 
    .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #5a5f71 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #5a5f71 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
    .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>